@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600;800;700&display=swap);
.navbar_container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #050b7f !important;
    height: 3rem;
}

.navbar_toolbar{
    justify-content: space-between;
    width: 95%;
    margin: auto;
}

.navbar_logo_txt{
    
    display: flex;
    color: white;
}

.navbar_logo_txt h2{
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 20pt;
    width: -webkit-max-content;
    width: max-content;
}

.navbar_logo_txt h4{
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-self: center;
    margin-left: 6px;
    font-size: 15pt;
    margin-top: 5pt;
    
}

.navbar_logo_content{
    display: flex;
    align-items: center;
}

.navbar_links{
    width: 15%;
    justify-content: space-between;
}

.navbar_links img,
.navbar_links .Icon{
    height: 20px;
    width: 21px;
    cursor: pointer;
}

.navbar_menuMobil_img{
    height: 100%;
}

.navbar_menuMobil_button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: 15px;
}


.navbar_options{
    height: 20px;
    width: calc(100% + 10pt);
    text-align: center;
    margin-top: 5pt;
    font-size: 10pt;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: #050b7f;
    background-color: #D0CFCF;
    border-radius: 3px;
}

.navbar_Notifications{
    height: 10%;
    display: flex;
    align-items: center;
}

.navbar_Notifications p{
    color: #050B7F;
    font-size: 14px;
    font-weight: 600;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    margin-left: 15px;
}

.navbar_menuMobil_Paper{
    background-color: #050b7f;
    text-transform: uppercase;
    width: 15rem;
    height: 3rem;
    margin-top: 1rem;
}

.navbar_menuMobil_Paper div{
    width: 100%;
    height: 100%;
    background-color: #050b7f;
}

.navbar_links_mobil{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.navbar_links_mobil .Icon{
    height: 65%;
}

.navbar_menuMobil_SideBar{
    height: 20px;
    margin-right: 3vw;
    margin-bottom: 3px;
}

@media (max-width: 960px) {
    .navbar_links{
        display: none;
        visibility: hidden;
    }
}

@media (min-width: 960px){
    .navbar_logo_txt{
        position: relative;
        right: 1.3vw;
    }
    .navbar_menuMobil_button,
    .navbar_menuMobil_SideBar{
        display: none;
        visibility: hidden;
    }
}

@media (min-width: 2500px){
    .navbar_logo_txt{
        position: relative;
        right: 2vw;
    }
}

@media (max-width: 440px){
    .navbar_menuMobil{
        margin-right: 10px;
    }
}

@media (max-width: 440px){
    .navbar_menuMobil_img{
        margin-right: 17px;
    }
}

@media (max-width: 300px){
    .navbar_logo_txt h2{
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        font-size: 15pt;
    }
    
    .navbar_logo_txt h4{
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        align-self: center;
        margin-left: 6px;
        font-size: 10pt;
        margin-top: 5pt;
        
    }
}

@media(max-width: 230px){

    .navbar_menuMobil_SideBar{
        height: 16px;
    }

    .navbar_logo_txt h2{
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        font-size: 12pt;
        margin-left: 5px;
        
    }
    
    .navbar_logo_txt h4{
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        align-self: center;
        margin-left: 6px;
        font-size: 8pt;
        margin-top: 5pt;
        
    }
}
/* Estilos de Sidebar Estandard */
/* Estilos de Sidebar sin Colapsar */
.Sidebar_button {
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    color: #050b7f;
}

.Sidebar_button .Icon {
    width: 23pt;
    height: 23pt;
    margin-left: 15px;
    margin-top: 6px;
    cursor: pointer;
}

.Sidebar_content {
    margin-inline: auto;
    margin-top: 1.5rem;
    width: 80%;
    height: 100%;
    color: #050b7f;
}

.Sidebar_content_home {
    height: 1.5rem;

}

.Sidebar_content_home p {
    margin-right: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    right: 17px;
    cursor: pointer;
}

.Sidebar_content_home p img {
    position: relative;
    left: 3px;
}


.Sidebar_content p {
    display: flex;
    align-items: center;
    font-size: 10pt;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #050b7f;
    margin-left: 3px;
    margin-top: 7px;
}

.Sidebar_Separador {
    margin-top: 13px;
    height: 0.5px;
    background-color: #050b7f;
}

.Sidebar_content p img {
    height: 23px;
    margin-right: 13px;
}

.Sidebar_content_title {
    font-weight: bold;
}

.sidebar_listItem {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
    padding-bottom: 1.5vh;

}

.sidebar_listItem_Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;

}

.sidebar_listItem_text {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 23px;
}

.sidebar_listItem_text p {
    padding-bottom: 6px;
}

.sidebar_category {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800 !important;
    font-size: 15pt;
    position: relative;
    right: 15px;

}

.Sidebar_categoryList {
    height: calc(100% + 5pt) !important;
    width: 90%;
}

.Sidebar_Title_Module {
    height: 6vh;
}

.Sidebar_Modules {
    justify-content: space-around;
    width: 100%;
    height: 100%;
    margin-inline: auto;
}

/* Estilos de Sidebar Colapsado */
/* SidebarCollapse */

.SidebarCollapse_button {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SidebarCollapse_button .Icon {
    color: #050b7f;
    width: 23pt;
    height: 23pt;
    cursor: pointer;
}

.SidebarCollapse_content {
    margin-inline: auto;
    margin-top: 2.5rem;
    width: 80%;
    height: 100%;
    color: #050b7f;
}

.SidebarCollapse_content p {
    display: flex;
    margin-inline: auto;
}

.SidebarCollapse_content p img {
    height: 23px;
    margin: auto;
}

.SidebarCollapse_Separador {
    margin-top: 13px;
    margin-inline: auto;
    width: 50%;
    height: 0.5px;
    background-color: #050b7f;
}

.sidebarCollapse_listItem {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
    padding-bottom: 1.5vh;
    margin-top: 0.5vh;
}

.Sidebar_categorySection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 25vh;
}

.SidebarCollapse_content_home p {
    width: 80%;
    position: relative;
    right: 4px;
}

.SidebarCollapse_Modules {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 100%;
    margin-inline: auto;
}

.Sidebar_Popper {
    width: calc(100% + 5pt);
    padding-left: 5px;
    font-size: 10pt;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #050b7f;
    background-color: #D0CFCF;
    border-radius: 3px;
}

@media(max-width: 960px) {
    .Sidebar_button img {
        visibility: hidden;
        display: none;
    }
}

@media(max-height: 600px) {
    .Sidebar_Separador {
        margin-top: 0px;
    }

    .sidebar_listItem {
        margin-top: 5px;
    }
}


@media(-webkit-min-device-pixel-ratio: 1.3541666666666667), (min-resolution: 130dpi) {

    .sidebar_listItem {
        height: 1.7em;
        padding-bottom: 0.5vh;
    }

}

@media(-webkit-min-device-pixel-ratio: 2.0833333333333335), (min-resolution: 200dpi) {
    .Sidebar_content {
        height: 28rem;
        position: relative;
    }
}

@media(-webkit-min-device-pixel-ratio: 1.0416666666666667), (min-resolution: 100dpi) {
    .SidebarCollapse_content {
        position: relative;
        height: 30rem;
        bottom: 20px;
    }
}

@media(min-width: 2000px) {
    .sidebar_listItem {
        height: 2.2em;
        padding-bottom: 0.5vh;
    }
}

.labelLoading {
    width: 160px;
    /* Ancho del contenedor */
    white-space: nowrap;
    /* Evita el salto de línea */
    overflow: hidden;
    /* Oculta el texto que desborda */
    text-overflow: ellipsis;
    /* Agrega los tres puntos (...) al final */
    position: relative;
    /* Necesario para posicionar el pseudo-elemento */
    font-weight: 500;
    margin-top: 25px;
}

.labelLoading::after {
    content: '...';
    /* Contenido del pseudo-elemento */
    position: absolute;
    /* Posicionamiento absoluto con respecto al contenedor */
    bottom: 0;
    /* Coloca los tres puntos en la parte inferior */
    right: 0;
    /* Coloca los tres puntos a la derecha */
    opacity: 0;
    /* Inicialmente oculto */
    animation: fade-in 1s infinite alternate;
    /* Aplica la animación */
}

@keyframes fade-in {
    from {
        opacity: 0;
        /* Opacidad inicial */
    }

    to {
        opacity: 1;
        /* Opacidad final */
    }
}
.AcceptCookies_container{
    font-family: 'Source Sans Pro', sans-serif;
}

.AcceptCookies_title{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #050B7F;
}

@media(max-width: 1300px){ 
    .AcceptCookies_subtitle{
        width: 90%;
    }
}

@media(-webkit-min-device-pixel-ratio: 1.3541666666666667), (min-resolution: 130dpi){
    .AcceptCookies_subtitle{
        width: 85%;
    }
}
.report-style-class {
	height: 95vh;
	margin: 1% auto;
	width: 100%;
}

body {
	font-family: 'Segoe UI';
	margin: 0;
}

.header {
	background: #3476AE 0 0 no-repeat padding-box;
	border: 1px solid #707070;
	height: 55px;
	left: 0;
	top: 0;
}

.controls {
	margin-top: 1%;
	text-align: center;
}

button {
	background: #337AB7;
	border: 0;
	border-radius: 5px;
	color: #FFFFFF;
	font-size: medium;
	height: 35px;
	margin-right: 15px;
}

button:onfocus {
	outline: none;
}

iframe {
	border: none;
}
.footer{
    display: flex;
    width: 100%;
    height: 3rem;
}

.footer_img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
}

.footer_container{
    width: 75%;
    height: 100%;
    display: flex;
    padding-top: 1em;
}

.footer_container_menu{
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
}

.footer_container_menu p{
    width: 10vw;
    text-align: center;
}

.footer_logo{
    height: 200%;
    margin-right: 3.75em;
}

.menu_item{
    font-size: 16px;
    color: #BDBDBD;
    border-left: 1px ;
    display: flex;
    font-family: 'Source Sans Pro', sans-serif;
    width: 33%;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.menu_item:hover{
    color: #BDBDBD;
}

.copyrigth{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
}

@media(max-width: 950px){
    .footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: -webkit-max-content;
        height: max-content;
    }

    .footer_logo{
        width: 150%;
    }

    .footer_container_menu{
        margin-top: 10px;
        display: block;
        width: 100%;  
    }

    .footer_container_menu p{
        display: none;
    }

    .footer_container{
        display: block;
        padding-left: 3em;
    }

    .menu_item{
        width: 100%;
        justify-content: flex-start;
    }

    .copyrigth{
        width: 100%;
    }

    .footer_img{
        margin-right: 3em;
    }

}
.Home_Content {
}

.Home_Card{
    height: calc(100% + 1pt);
}

@media (max-width: 1280px) {
    .Home_Content{
        margin-top: -40px;
    }   
}
* {
    margin: 0;
    padding: 0;
}

body{
    width: 100%;
    height: 100%;
}

/* Carrusel */

.cb-slideshow,
.cb-slideshow:after { 
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0; 
}
.cb-slideshow:after { 
    content: '';
}

.cb-slideshow li span { 
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    color: transparent;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: none;
    opacity: 0;
    z-index: 0;
    animation: imageAnimation 76s linear infinite 0s; 
}

.cb-slideshow li div { 
    z-index: 1000;
    position: absolute;
    bottom: 30px;
    left: 0px;
    width: 100%;
    text-align: center;
    opacity: 0;
    color: #fff;
    animation: titleAnimation 76s linear infinite 0s; 
}
.cb-slideshow li div h3 { 
    font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
    font-size: 240px;
    padding: 0;
    line-height: 200px; 
}

.cb-slideshow li:nth-child(1) span { 
    background-image: url(/static/media/Carro1.f881875f.png) 
}
.cb-slideshow li:nth-child(2) span { 
    background-image: url(/static/media/Carro2.70d5a44e.png);
    animation-delay: 6s; 
}
.cb-slideshow li:nth-child(3) span { 
    background-image: url(/static/media/Carro3.86b4101c.png);
    animation-delay: 12s; 
}
.cb-slideshow li:nth-child(4) span { 
    background-image: url(/static/media/Carro4.5214f4ed.png);
    animation-delay: 18s; 
}
.cb-slideshow li:nth-child(5) span { 
    background-image: url(/static/media/Tecnologia1.9bb87dcf.png);
    animation-delay: 24s; 
}
.cb-slideshow li:nth-child(6) span { 
    background-image: url(/static/media/Tecnologia2.2bd0f389.png);
    animation-delay: 30s; 
}

.cb-slideshow li:nth-child(7) span { 
    background-image: url(/static/media/Tecnologia3.93834abd.png);
    animation-delay: 36s; 
}

.cb-slideshow li:nth-child(8) span { 
    background-image: url(/static/media/Tecnologia4.f4b339e5.png);
    animation-delay: 42s; 
}

.cb-slideshow li:nth-child(9) span { 
    background-image: url(/static/media/Sustentabilidade1.aea7cba0.png);
    animation-delay: 48s; 
}

.cb-slideshow li:nth-child(10) span { 
    background-image: url(/static/media/Sustentabilidade2.5bae4dc9.png);
    animation-delay: 54s; 
}

.cb-slideshow li:nth-child(11) span { 
    background-image: url(/static/media/Sustentabilidade3.e895a3c5.png);
    animation-delay: 60s; 
}

.cb-slideshow li:nth-child(12) span { 
    background-image: url(/static/media/Sustentabilidade4.b88f4cbc.png);
    animation-delay: 66s; 
}

.cb-slideshow li:nth-child(2) div { 
    animation-delay: 6s; 
}
.cb-slideshow li:nth-child(3) div { 
    animation-delay: 12s; 
}
.cb-slideshow li:nth-child(4) div { 
    animation-delay: 18s; 
}
.cb-slideshow li:nth-child(5) div { 
    animation-delay: 24s; 
}
.cb-slideshow li:nth-child(6) div { 
    animation-delay: 30s; 
}
.cb-slideshow li:nth-child(7) div { 
    animation-delay: 36s; 
}
.cb-slideshow li:nth-child(8) div { 
    animation-delay: 42s; 
}
.cb-slideshow li:nth-child(9) div { 
    animation-delay: 48s; 
}
.cb-slideshow li:nth-child(10) div { 
    animation-delay: 54s; 
}
.cb-slideshow li:nth-child(11) div { 
    animation-delay: 60s; 
}
.cb-slideshow li:nth-child(12) div { 
    animation-delay: 66s; 
}



/* design form */

.Login_textInput{
    margin-top: 1rem;
}

.welcomeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 4px solid #000383;
    border-left: 4px solid #000383;
    border-radius: 0px 0px 0vh 4vh;
    margin: auto;
    width: 70%;
    height: 8.5rem;
}

.welcomeText{
    padding-left: 1em;
    text-align: left;
    height: 50%;
    width: 100%;
}

.welcomeText h2{
    display: flex;
    align-items: center;
    height: 1em;
    color: #0092FF;
    font-size: 3em;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

.subtitleLogin{
    color: #000383;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 11pt;
    margin-left: 2pt;
}

.divImg{
    height: 100%;
    width: 100%;
}


.contRememberSession{
    display: flex;
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
    justify-content: space-between;
    margin-top: 15px;
}

.contRememberSession input{
    height: 100%;
}

.divCont{
    display: flex;
    align-items: center;
    height: 100%;
    width: 48em;
    
}

.form{
    margin: auto;
    background-color: rgb(255, 255, 255);
    width: 28em;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.centralMenu{
    width: 70%;
    margin: auto;
}

.labelLoginStyle{
    margin-top: 18px;
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    max-width: 25rem;
    flex-direction: column;
    font-size: 11pt;
    font-family: 'Source Sans Pro', sans-serif !important;
    color: rgb(130,130,130);
}

.textNewPass_login{
    margin-bottom: 10px;
    width: 21em;
}

.rememberPassword{
    text-decoration: underline;
    color: rgb(130,130,130);
    font-size: 12pt;
    font-family: 'Source Sans Pro', sans-serif;
    cursor: pointer;
}

.rememberSession{
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    font-size: 12pt;
    font-family: 'Source Sans Pro', sans-serif;
}

.userTypeCheckBox{
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #828282
}

.userType{
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    font-size: 13px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #828282;
}

.checkboxNotification:checked {
    background: #56BC23;
    color: white;
  }
  
  .checkboxNotification {
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    outline: 0;
    background: lightgray;
    height: 20px;
    width: 20px;
    border: 1px solid white;
    color: white;
  }
  
  .checkboxNotification:after {
    content: ' ';
    position: relative;
    left: 30%;
    top: 10%;
    width: 20%;
    height: 50%;
    border: 2px solid #fff;
    border-width: 0 2.5px 3px 0;
    transform: rotate(40deg);
    display: none;
  }
  
  .checkboxNotification:checked:after {
    display: block;
  }

.buttonLogin{
    font-family: 'Source Sans Pro', sans-serif;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inputLogin{
    font-family: 'Source Sans Pro', sans-serif !important;
}

.loginLogo{
    margin-top: 1em;
    display: flex;
    justify-content: center;
}

.buttonLogin img{
    width: 50%;
    margin-right: 1em;
    margin-bottom: 1.5em;
}

.MuiInputLabel-root{
    font-family: 'Source Sans Pro', sans-serif;
}

.loginButton{
    text-transform: uppercase;
    background-color: #0092FF;
    border: none;
    border-radius: 8px;
    height: 40px;
    width: 150px;
    outline: 0;
    margin-top: 10px;
    cursor: pointer;
}

.loginButton:hover{
    box-shadow: 0 0 2px 2px grey;
}



@media(min-width: 990px){
    .Login_textInput{
        width: 100%;
    }
    .divImg{
        position: absolute;
        z-index: 0;
    }

    .divCont{
        position: absolute;
        z-index: 1;
    }

    .form{
        border-radius: 20px;
    }
    .Login_textInput{
        width: 22em;
    }
}

@media(max-width: 990px){
    .cb-slideshow{
        display: none;
    }

    .divCont{
        height: 100vh;
        width: 100%;
        background-color: #fff;
    }

    .loginButton{
        margin: 8px auto;
        width: 70vw;
    }
    .textNewPass_login{
        width: 100%;
    }

    
}

@media(min-width: 1700px){
    .divCont{
        height: 100%;
    }
}

@media(max-width: 600px){
    .subtitleLogin{
        font-size: 9pt;
    }

}

@media(max-width: 300px){
    .welcomeText h2{
        font-size: 2em;
    }
}


/* Animacion */

@keyframes imageAnimation { 
    0% { opacity: 0; animation-timing-function: ease-in; }
    8% { opacity: 1; animation-timing-function: ease-out; }
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
}
/*!

 =========================================================
 * Material Dashboard React - v1.9.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2020 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  color: #644AF3;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

.MuiTableCell-stickyHeader{
  z-index: 0 !important;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}
.fixed-plugin {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
  /* .badge-primary-background-color: #644AF3; */
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li > a:hover,
.fixed-plugin .dropdown-menu li > a:focus {
  box-shadow: none;
}
.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #644AF3;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}
.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}
.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}
.fixed-plugin .adjustments-line .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder img {
  margin-top: auto;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}
.fixed-plugin .dropdown .dropdown-menu {
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}
.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  transform: translateY(-13%);
  transform-origin: 0 0;
}
.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}
.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}
* {
  letter-spacing: normal !important;
}

